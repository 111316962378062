import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">
                    Terms &amp; Conditions
                  </h2>
                  <p className="gr-text-8 mb-5">
                    We display our legal texts online in English. For other
                    languages or understanding problems, please contact
                    info@trustify.ch. Thanks.
                  </p>
                  <h3 className="gr-text-7 mb-13">
                    <a href="https://trustify.ch/">Back to startpage</a>
                  </h3>
                </div>
                <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold">Introduction</h3>
                  <p className="gr-text-9 pb-5">
                    These general terms and conditions of use (hereinafter
                    "Terms of Use") apply to any use of the Trustify websites
                    (hereinafter "Website"), including but not limited to
                    app.trustify.ch, in.trustify.ch and similar websites.
                    <br></br>
                    <br></br>The Website and Services are provided by NAVEST
                    GmbH, Gliserallee 144, 3902 Brig-Glis, Switzerland. In these
                    Terms of Use, the words "we", "us" and "our" refer to
                    Trustify respectively to NAVEST GmbH.
                    <br></br>
                    <br></br>
                    We may change this Terms of Use, including our Privacy
                    Policy under https://www.trustify.ch/privacy-policy/, at any
                    time without prior notice. The current version published on
                    our website applies.
                    <br></br>
                    <br></br>
                  </p>
                </div>
                <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold">Registration</h3>
                  <p className="gr-text-9 pb-5">
                    For unrestricted access to use the Website and our Services,
                    you must register as a user (hereinafter "Registered User"
                    and "Customer").
                    <br></br>
                    <br></br>Non-registered users, such as those who leave
                    reviews, are hereinafter also referred to as "Visitor". If
                    they are not explicitly mentioned and it is obvious or in
                    the sense of Trustify that they are included in a
                    regulation, then they are automatically included.
                  </p>
                  <ul className="list-unstyled">
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      By registering, a contract on the use of the service is
                      concluded between the customer and Trustify. The
                      registration and the creation of a user account
                      (hereinafter "Profile" and "Account") is free of charge
                      for the customer.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Generating and trading with manipulated reviews and
                      ratings contradicts the values of Trustify. Therefore,
                      companies that generate or trade with reviews and reatings
                      are excluded from use.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Minors and persons under guardianship are not allowed to
                      register.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      The customer is obliged to provide the data collected
                      during registration truthfully and completely. The use of
                      pseudonyms or stage names is not permitted. In case of a
                      change of the collected data after registration, the
                      customer has to update the information in his profile
                      immediately.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      By submitting the registration form, the customer agrees
                      to these Terms of Use and affirms that he/she has provided
                      truthful personal information and is not excluded from
                      using the service.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Multiple registrations and the creation of multiple
                      accounts are prohibited. Except with the written consent
                      of Trustify.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      There is no right for registration. Trustify reserves the
                      right to refuse the registration of the customer without
                      giving reasons.
                    </li>
                  </ul>
                  <p className="gr-text-9 pb-5">
                    <br></br>
                    We may at any time, without prior notice, delete, suspend or
                    modify your profile in the event of a violation or suspected
                    violation of these Terms of Use or applicable law. This
                    applies in particular:
                  </p>
                  <ul className="list-unstyled">
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      If false information was provided during registration.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      If the customer was not allowed for registration.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      In case of loss or suspected misuse of the access data by
                      a third party.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      If the customer has violated the Terms of Use or law.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      As well as other reasons such as suspected manipulation of
                      the system, multiple registrations, fraud, payment
                      reclaim, manipulation of ratings, content that contradicts
                      the values of Trustify and other, important reasons.
                      <br></br>
                      <br></br>
                      In case of deletion of your profile, we reserve the right
                      to delete content created by you on the Website and
                      user-generated content.
                      <br></br>
                      <br></br>
                      In addition, we reserve the right to delete your profile
                      and user-generated content at any time, without prior
                      notice or explanation.
                    </li>
                  </ul>
                </div>
                <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold pb-5">
                    User generated content
                  </h3>
                  <p className="gr-text-9">
                    To the fullest extent permitted by law, Trustify is granted
                    a non-exclusive license (including all intellectual property
                    rights) to any materials, information, messages, reviews,
                    articles or other communications (hereinafter referred to as
                    "User-Generated Content" or "UGC") created by Registered
                    Users and Visitors on the Site. Registered User shall not be
                    entitled to any royalty payments or other compensation or
                    fees in connection with this UGC. We may freely use and
                    transfer this UGC. Our non-exclusive right to freely use the
                    UGC is irrevocable and not limited in time or space.
                  </p>
                  <p className="gr-text-9">
                    Registered users and Visitors are liable for the UGC they
                    post on the website.
                  </p>
                  <p className="gr-text-9">
                    Registered Users and Visitors warrant that any UGC posted on
                    the Site is accurate and true (in the case of fact) or
                    sincere (in the case of opinion).
                  </p>
                  <p className="gr-text-9">
                    Registered users and Visitors may not post any UGC on the
                    Site that is is promotional in nature or purpose, unlawful,
                    deceptive, misleading, fraudulent, threatening, abusive,
                    harassing, abusive, defamatory, tortious, obscene,
                    pornographic, profane, sexist, political, racist, infringing
                    of another's rights (including any intellectual property
                    rights), objectionable, or in any other way violates any
                    applicable local, national or international law or
                    regulation, violates these Terms of Use serves an unfaithful
                    or unlawful purpose and/or contains such content (or
                    promotes unlawful purposes), is technically harmful
                    (including, without limitation, computer viruses, logic
                    bombs, Trojan horses, worms, harmful components, corrupt
                    data or other malicious software, harmful data or harmful
                    conduct).
                  </p>
                  <p className="gr-text-9">
                    UGC creators warrant that the UGC is lawful in all respects
                    and complies with the Terms of Use. If we are notified or
                    otherwise become aware that the UGC violates applicable law
                    and/or the Terms of Use, we may delete the UGC without
                    notice as well as, depending on the nature of the violation,
                    notify the violating party and/or the authorities. Our right
                    to delete is not dependent on any justification, although we
                    will endeavor to inform the registered user about the
                    deletion and its reasons.
                  </p>
                  <p className="gr-text-9">
                    The Registered User or Visitor will indemnify us in respect
                    of any claim brought against us as a result of any breach by
                    the Registered User or Visitor of the Terms of Use or
                    applicable law. The Registered User or Visitor shall
                    indemnify us with respect to any claim or loss arising from
                    any third party claim against us based on the Registered
                    User's and Visitor's UGC.
                  </p>
                  <p className="gr-text-9">
                    We may at any time require the registered user or visitor to
                    provide information about the UGC, including documentation
                    supporting the content of the UGC. For example, the
                    registered user and visitor must demonstrate that the UGC is
                    based on an actual purchase or service experience in a
                    genuine customer relationship with the company, person or
                    business to which the UGC relates.
                  </p>
                </div>
                <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold pb-5">
                    Duties and regulations
                  </h3>
                  <p className="gr-text-9">
                    In general, the registered user and visitor is prohibited
                    from using this website and services of Trustify in any way
                    that is not in accordance with the purpose and intent of
                    Trustify.
                  </p>
                  <ul className="list-unstyled">
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      The registered user assures that the customers contacted
                      by him in the context of a customer survey via the service
                      have previously consented to the sending of the relevant
                      e-mail. The registered user is responsible for obtaining
                      such consent.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Trustify awards and Widgets may not be modified in any way
                      and, if the function exists, must be linked to the
                      associated profile. In addition, it is not permitted to
                      make content available for retrieval on the websites in
                      which one of the Trustify components above is integrated
                      that is illegal or immoral, discriminatory, racist,
                      sexist, offensive, glorifies violence or pornographic.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Trustify Widgets may only be included on those websites of
                      the registered user that offer goods and/or services that
                      are the subject of the ratings shown in the Widgets.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      The registered user has the possibility to request overall
                      ratings from other platforms. Trustify provides this
                      option only for selected platforms and will show the
                      overall rating in the registered user's public profile
                      page. It is possible that Trustify changes the overall
                      rating for the Trustify rating scale. Trustify may also
                      remove individual platforms entirely and permanently at
                      any time and may also restrict the function and remove
                      individual platforms for individual registered users. The
                      registered user is also obliged to provide only platform
                      profiles whose ratings also correspond to the business,
                      service, company, person, product, etc. of his Trustify
                      profile page. For certain platforms, we request user data
                      of the registered user from the respective platform,
                      sometimes require access and establish connections. This
                      happens in each case with the consent of the registered
                      user. We never request passwords. Corresponding platforms
                      can cancel possible connections or revoke access for
                      Trustify at any time.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Influenced, fake, bought reviews and reviews created by
                      bots are contrary to Trustify's views and are strictly
                      forbidden. Each user is obligated to immediately report
                      the corresponding Trustify profile to Trustify if such
                      ratings are detected or if there is a suspicion. Trustify
                      is allowed to block and delete profiles temporarily or
                      permanently for this reason at any time. Similarly,
                      Trustify can obtain information about individual reviews
                      and remove them without warning. Adding such reviews on
                      other platforms and listing them on Trustify will also not
                      be tolerated and may result in the blocking or deletion of
                      the source or Trustify profile.
                    </li>
                  </ul>
                </div>
                <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold pb-5">
                    Regulations for reviews, the submission of reviews and other
                    functions
                  </h3>
                  <p className="gr-text-9">
                    Only those who have actually used the services of the
                    registered user to a significant extent may be admitted to
                    submit a review or be invited as part of a customer survey.{" "}
                  </p>
                  <p className="gr-text-9">
                    The following recommendations and guidelines should be
                    followed. Otherwise, this may lead to the permanent deletion
                    of a review or even all reviews of the rater.
                  </p>
                  <ul className="list-unstyled">
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Don't make up experiences or give reviews for other people
                      - leave it to them to write their own review. And if you
                      have a close relationship with a company, work for it, or
                      are a competitor, don't rate it.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Keep proof of your experience with the company (e.g., a
                      receipt, an order or purchase confirmation, a screenshot
                      of your chat with online customer service) - you may be
                      asked for this.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Advertising and promotional content or even solicitation
                      (promote other businesses or products) is not allowed.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Ratings are publicly available. Accordingly, do not reveal
                      any confidential or personal data. And it is not permitted
                      under any conditions to include such information of other
                      persons. This includes names, telephone numbers,
                      addresses, email addresses and other information that
                      could be used to locate, identify, contact or impersonate
                      any person.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Try to keep reviews constructive, friendly and make sure
                      your reviews are relevant. Constructive feedback is the
                      best way to help a provider improve, which is the goal and
                      not to cause them damage.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      We expect users of our portal to treat each other with
                      respect. Do not post harmful, inflammatory,
                      discriminatory, defamatory or obscene content. And refrain
                      from any lies, harassment, extortion attempts, threats and
                      illegal activities.
                    </li>
                  </ul>
                  <p className="gr-text-9">
                    For a review to become publicly visible, it must first be
                    verified by the rater. This is normally done by an e-mail.
                    It is also possible to configure for a Trustify profile
                    whether new reviews should be directly visible or hidden.
                    Accordingly, new and verified reviews may not be visible
                    immediately. However, these already count to the total score
                    of the respective Trustify profile after verification.
                  </p>
                  <p className="gr-text-9">
                    It is possible that not all ratings are displayed in a
                    Trustify profile. The reasons for this are mostly: review of
                    reviews, hidden reviews, unverified reviews which is
                    considered as an invalid review, the Trustify profile has
                    reached the maximum number of reviews for its plan, filter
                    settings, etc.
                  </p>
                  <p className="gr-text-9">
                    A sent and verified rating can no longer be changed or
                    deleted independently by the rater. The rater has to contact
                    Trustify for this purpose. However, Trustify does not have
                    to provide this.
                  </p>
                  <p className="gr-text-9">
                    Trustify does not, by default, verify the accuracy of the
                    information and statements contained in reviews.
                  </p>
                  <p className="gr-text-9">
                    There is no right to rate, change or delete ratings and also
                    no security and history for ratings (data backup and data
                    tracing), although we are extremely concerned about data
                    backup.
                  </p>
                </div>
                <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold pb-5">
                    Personal Data & Privacy
                  </h3>
                  <p className="gr-text-9">
                    We perform different types of processing of personal data in
                    connection with the use of the website and Trustify
                    services. The processing of personal data is carried out in
                    compliance with our privacy policy, which can be accessed
                    here: https://www.trustify.ch/privacy-policy <br></br>By
                    agreeing to these Terms of Use, you again explicitly confirm
                    that you have read and accepted our Privacy Policy.
                  </p>
                </div>
                <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold pb-5">
                    Exclusion of liability
                  </h3>
                  <p className="gr-text-9">
                    Trustify hereby releases itself from any liability to the
                    fullest extent permitted by law. Following further details.
                  </p>
                  <ul className="list-unstyled">
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Website, content and services offered on the Website are
                      provided on an "as is" basis ("ohne Mängelgewähr" in the
                      Swiss law). We make no warranties as to the content or
                      accuracy of the UGC or the Website and assume no liability
                      for our own or any third party's deletion or blocking of
                      the UGC. We may delete any content on the Website at any
                      time in our sole discretion and without any liability. We
                      expressly exclude any conditions, warranties and other
                      terms otherwise granted by statute, common law or equity.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      We have no obligation to review or monitor UGC posted on
                      the Site. Regardless, we may review, remove and edit the
                      UGC at any time (including making it anonymous).
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      We are not liable for the content of the UGC. Our
                      disclaimer applies to any UGC, including UGC that is
                      edited by us. We are not liable for any links contained in
                      the UGC to third-party websites, including the content of
                      the website to which the UGC links.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Recommendations, reviews, comments, etc. regarding
                      specific companies, services, e-business, etc. on the
                      website are only guidelines. We are in no way liable for
                      the content of the website. The use of Trustify is in all
                      respects the sole responsibility of the users.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      We are not liable for the availability of the website.
                      Trustify is extremely concerned about data backup and data
                      security, but we are also not liable for data loss. No
                      matter how the data loss was caused.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      In no event will we be liable for any damages arising from
                      the use of the Website and Trustify services, including
                      lost profits, loss of orders, loss of revenue, loss of
                      earnings, loss of business, loss or corruption of data,
                      recovery of data, loss of goodwill, security breaches
                      caused by the failure of third party telecommunications
                      services and/or the Internet, anticipated savings or
                      revenues (whether direct, indirect or consequential), any
                      loss or damage incurred in connection with liabilities to
                      third parties (whether direct, indirect or consequential),
                      or any indirect or consequential loss or damage of any
                      kind.
                    </li>
                    <li className="mt-7">
                      <i
                        className="rounded-circle bg-dark d-inline-block mr-5"
                        css={`
                          width: 9px;
                          height: 9px;
                        `}
                      ></i>
                      Neither our liability for fraudulent misrepresentation or
                      misstatement of material fact nor any other liability that
                      cannot be excluded or limited under applicable law is
                      limited or excluded by these Terms of Use.
                    </li>
                  </ul>
                </div>
                <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold pb-5">
                    Further terms of use
                  </h3>
                  <p className="gr-text-9">
                    We may revise or modify these Terms of Use at any time
                    without notice. We also reserve the right, in our sole
                    discretion and without prior notice, to modify, redesign or
                    discontinue the Website and Trustify services at any time.
                    As a registered user, you agree to be bound by the
                    then-current version of the Terms of Use. Any revisions or
                    modifications to the Terms of Use will be posted on the site
                    here. Registered users agree that by continuing to use the
                    Website after the new version of the Terms of Use is posted,
                    they consent to be governed by the modified Terms of Use.
                  </p>
                  <p className="gr-text-9">
                    If any provision of these Terms of Use is found to be
                    illegal or invalid and therefore cannot be enforced, the
                    validity and enforceability of the remainder of the Terms of
                    Use shall not be affected.
                  </p>
                  <p className="gr-text-9">
                    In the event of disputes, Swiss law applies exclusively and
                    the place of jurisdiction is Switzerland, Brig.
                    <br></br>
                    <br></br>
                    Brig-Glis, 10.09.2021
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8"></Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
